import { isPlatformBrowser } from '@angular/common';
import type { OnDestroy } from '@angular/core';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@freelancer/activated-route';
import { Auth } from '@freelancer/auth';
import { Datastore } from '@freelancer/datastore';
import type {
  CurrenciesCollection,
  Currency,
  CurrencyDetectCollection,
  ReferralTokenCollection,
  UsersCollection,
} from '@freelancer/datastore/collections';
import { LocalStorage } from '@freelancer/local-storage';
import { REFERRER_QUERY_PARAM } from '@freelancer/share';
import { isDefined } from '@freelancer/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CookieService } from 'ngx-cookie';
import type { Observable } from 'rxjs';
import {
  Subscription,
  combineLatest,
  filter,
  map,
  merge,
  switchMap,
  take,
} from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class GiveGetService implements OnDestroy {
  DEFAULT_CURRENCY_ID = 1;
  USD_AMOUNT_THRESHOLD = 1;
  DEFAULT_BONUS_AMOUNT = 20;

  private subscriptions = new Subscription();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private activatedRoute: ActivatedRoute,
    private auth: Auth,
    private cookies: CookieService,
    private localStorage: LocalStorage,
    private datastore: Datastore,
  ) {}

  getCurrencyToUse(): Observable<Currency> {
    const loggedInUserDoc = this.datastore.document<UsersCollection>(
      'users',
      this.auth.getUserId(),
    );

    const loggedInUserCurrencyId$ = loggedInUserDoc.valueChanges().pipe(
      map(user => user.currency),
      filter(isDefined),
      map(currency => currency.id),
    );

    const loggedOutUserCurrencyCollection =
      this.datastore.document<CurrencyDetectCollection>('currencyDetect');

    const loggedOutUserCurrencyId$ = this.auth.isLoggedIn().pipe(
      filter(isLoggedIn => !isLoggedIn),
      switchMap(() => loggedOutUserCurrencyCollection.valueChanges()),
      map(currency => currency.id),
    );

    const currencies$ = this.datastore
      .collection<CurrenciesCollection>('currencies')
      .valueChanges();

    return combineLatest([
      merge(loggedInUserCurrencyId$, loggedOutUserCurrencyId$).pipe(take(1)),
      currencies$,
    ]).pipe(
      map(([userCurrencyId, currencies]) => {
        const userCurrency = currencies.find(
          currency => currency.id === userCurrencyId,
        );

        if (!userCurrency) {
          // Default to USD
          return currencies.find(currency => currency.id === 1);
        }

        return userCurrency;
      }),
      filter(isDefined),
    );
  }

  getBonusAmount(currencyToUse$: Observable<Currency>): Observable<number> {
    const currencies$ = this.datastore
      .collection<CurrenciesCollection>('currencies')
      .valueChanges();

    const usdCurrency$ = currencies$.pipe(
      map(currencies => currencies.find(currency => currency.code === 'USD')),
      filter(isDefined),
    );
    return combineLatest([usdCurrency$, currencyToUse$]).pipe(
      map(([fromCurrency, toCurrency]) => {
        // TODO: T282656 replace with generic endpoint
        // Copied logic from cleanConvertCurrency.php
        if (
          toCurrency.id !== this.DEFAULT_CURRENCY_ID &&
          fromCurrency?.exchangeRate &&
          toCurrency?.exchangeRate
        ) {
          const exchangeThreshold =
            this.USD_AMOUNT_THRESHOLD / toCurrency.exchangeRate;

          const rawConversion =
            (this.DEFAULT_BONUS_AMOUNT * fromCurrency.exchangeRate) /
            toCurrency?.exchangeRate;

          const threshold = Math.round(rawConversion - exchangeThreshold);
          let conversion = Math.round(rawConversion);
          let previousConversion = conversion;

          let zeroes = 1;
          while (threshold < conversion) {
            previousConversion = conversion;
            conversion = Math.floor(conversion / 10 ** zeroes);
            conversion *= 10 ** zeroes;
            zeroes += 1;
            if (conversion.toString().length <= zeroes) {
              break;
            }
          }

          conversion = previousConversion;
          if (conversion > 10) {
            conversion = Math.floor(conversion / 5) * 5;
          }

          return conversion;
        }
        return this.DEFAULT_BONUS_AMOUNT;
      }),
    );
  }

  setReferralTokenCookie(): void {
    if (isPlatformBrowser(this.platformId)) {
      const localUsername$ = this.localStorage.get('giveGetReferrerUsername');

      const latestQueryParamLocaStorage$ = combineLatest([
        this.activatedRoute.queryParams.pipe(
          map(queryParamMap => queryParamMap[REFERRER_QUERY_PARAM]),
        ),
        localUsername$,
      ]);

      const referrerUsername$ = latestQueryParamLocaStorage$.pipe(
        switchMap(async ([queryParamUserName, localUsername]) => {
          return queryParamUserName || localUsername;
        }),
        filter(isDefined),
      );

      const referralTokenCollection =
        this.datastore.collection<ReferralTokenCollection>(
          'referralToken',
          query =>
            query.search(
              referrerUsername$.pipe(
                map(username => ({
                  referrer_username: username,
                })),
              ),
            ),
        );

      this.subscriptions.add(
        combineLatest([
          referralTokenCollection.valueChanges(),
          this.auth.isLoggedIn(),
        ]).subscribe(([referralTokens, isLoggedIn]) => {
          if (referralTokens.length > 0 && !isLoggedIn) {
            this.cookies.put('referral_signup', referralTokens[0].id);
          }
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
