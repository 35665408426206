import { isPlatformBrowser } from '@angular/common';
import type {
  AfterViewInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import type { HeartbeatTrackingCancellation } from './tracking.model';
import { Tracking } from './tracking.service';

/**
 * This directive adds heartbeat tracking whenever we display an `fl-spinner` on the page.
 * Note that you do not need to do anything with this directive for it to work!
 * It will automatically track busy buttons and spinners if you provide a tracking label.
 *
 * It *is* a little hacky.
 *
 * This directive hooks into all `[busy]` inputs and `fl-spinner` elements on the page,
 * and turns on heartbeat tracking when `[busy]=true` or when the spinner is shown.
 * The tracking is stopped when `[busy]=false` again.
 *
 * The main rationale for doing it in this directive is so that we don't have to
 * couple Bits to Tracking . If we wanted to add an `fl-heartbeat-tracking`component
 * inside the `fl-spinner` component, we would need to import the `TrackingModule`,
 * which would require a bunch of setup and add a useless dependency.
 */
@Directive({
  selector: `
    fl-spinner,
    fl-button[busy]
  `,
})
export class SpinnerHeartbeatDirective
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() flTrackingLabel?: string;
  @Input() flTrackingReferenceType?: string;
  @Input() flTrackingReferenceId?: string | number;

  // hook onto a button's busy input
  @Input() busy?: boolean;

  spinnerStartObserver: IntersectionObserver | undefined;
  cancel?: HeartbeatTrackingCancellation;

  constructor(
    private element: ElementRef<HTMLElement>,
    private tracking: Tracking,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngAfterViewInit(): void {
    if (!this.flTrackingLabel || !isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.element.nativeElement.tagName === 'FL-SPINNER') {
      this.spinnerStartObserver = new IntersectionObserver(async ([entry]) => {
        if (entry.isIntersecting && !this.cancel) {
          this.cancel = await this.tracking.trackHeartbeat(
            `${this.flTrackingLabel}.Spinner`,
            this.flTrackingReferenceType,
            this.flTrackingReferenceId?.toString(),
          );
        }
      });
      this.spinnerStartObserver.observe(this.element.nativeElement);
    }
  }

  isInView(): Promise<boolean> {
    return new Promise(resolve => {
      if (this.element.nativeElement) {
        const observer = new IntersectionObserver(([entry]) => {
          resolve(entry.isIntersecting);
          observer.disconnect();
        });

        observer.observe(this.element.nativeElement);
      } else {
        resolve(false);
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!this.flTrackingLabel || !isPlatformBrowser(this.platformId)) {
      return;
    }

    // this is a button with a busy input
    if ('busy' in changes) {
      if (this.busy && !this.cancel) {
        if (await this.isInView()) {
          this.cancel = await this.tracking.trackHeartbeat(
            `${this.flTrackingLabel}.ButtonSpinner`,
            this.flTrackingReferenceType,
            this.flTrackingReferenceId?.toString(),
          );
        }
      } else if (!this.busy && this.cancel) {
        // busy is false: disable tracking if started
        this.cancel();
        this.cancel = undefined;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.cancel) {
      this.cancel();
      this.cancel = undefined;
    }

    if (this.spinnerStartObserver) {
      this.spinnerStartObserver.unobserve(this.element.nativeElement);
      this.spinnerStartObserver.disconnect();
    }
  }
}
