import type { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import {
  Directive,
  ElementRef,
  ErrorHandler,
  Input,
  NgZone,
  Optional,
} from '@angular/core';
import { Location } from '@freelancer/location';
import { Subscription, fromEvent } from 'rxjs';
import { TrackingSectionDirective } from './tracking-section.directive';
import { PixelParams, Tracking } from './tracking.service';

@Directive({
  selector: `
    [flTrackingLabel]
  `,
})
export class TrackingDirective implements AfterViewInit, OnInit, OnDestroy {
  @Input() flTrackingLabel: string;
  @Input() flTrackingExtraParams?: {
    [k: string]: string | number | readonly string[];
  };
  @Input() flTrackingReferenceType?: string;
  @Input() flTrackingReferenceId?: string | number;
  // Name of the custom event for conversion tracking.
  @Input() flTrackingConversion?: string;
  // Data for Pixel Tracking
  @Input() flTrackingPixelParams?: PixelParams;

  private currentUrl: string;
  private urlBeforeDestroy: string;

  private trackLastLocationSubscription?: Subscription;
  private eventSubscriptions = new Subscription();

  constructor(
    private element: ElementRef<HTMLElement>,
    private t: Tracking,
    private location: Location,
    private errorHandler: ErrorHandler,
    private ngZone: NgZone,
    @Optional() private trackingSection: TrackingSectionDirective,
  ) {}

  ngOnInit(): void {
    // when the user action triggers a navigation, this.renderer.listen doesn't
    // necessary gets called before said navigation as rendering the new page
    // matters more than sending tracking beacons.
    // for that reason we track the last location before that ngOnDestroy is
    // called (if it is), and sent it alongside the event
    this.trackLastLocationSubscription = this.location
      .valueChanges()
      .subscribe(event => {
        this.currentUrl = event.href;
      });
  }

  ngAfterViewInit(): void {
    const eventsToTrack = this.tagToEvents(this.element.nativeElement.tagName);
    if (eventsToTrack.length > 0 && !this.trackingSection) {
      // display element to dev
      this.errorHandler.handleError(
        new Error(
          `Please provide an flTrackingSection for "${this.flTrackingLabel}"`,
        ),
      );
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      eventsToTrack.forEach(eventName => {
        this.eventSubscriptions.add(
          fromEvent(this.element.nativeElement, eventName).subscribe(e => {
            this.t.track('user_action', {
              label: this.flTrackingLabel,
              section: this.trackingSection.flTrackingSection,
              action: e.type, // e.g., 'click'
              location: this.urlBeforeDestroy || this.currentUrl,
              extra_params: this.flTrackingExtraParams,
              reference: this.flTrackingReferenceType,
              reference_id: this.flTrackingReferenceId,
            });

            // Send a custom tracking event for conversion tracking.
            if (this.flTrackingConversion) {
              this.t.track(
                'custom_event',
                {
                  label: this.flTrackingLabel,
                  name: this.flTrackingConversion,
                  section: this.trackingSection.flTrackingSection,
                  location: this.urlBeforeDestroy || this.currentUrl,
                  extra_params: this.flTrackingExtraParams,
                },
                this.flTrackingPixelParams,
              );
            }
          }),
        );
      });
    });
  }

  ngOnDestroy(): void {
    this.urlBeforeDestroy = this.currentUrl;

    this.trackLastLocationSubscription?.unsubscribe();
    this.eventSubscriptions.unsubscribe();
  }

  private tagToEvents(tagName: string): string[] {
    // spinner tracking is done in a separate directive
    if (tagName === 'FL-SPINNER') {
      return [];
    }

    if (tagName && ['FL-INPUT', 'FL-TEXTAREA'].includes(tagName)) {
      return ['focusin', 'focusout'];
    }
    return ['click'];
  }
}
