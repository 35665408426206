export type ShareByURLAction = 'share' | 'copy';

export interface ShareInformation {
  url: string;
  title?: string;
  text?: string;
  modalTitle?: string;
}

export const REFERRER_QUERY_PARAM = 'frm';
